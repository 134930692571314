import React from "react";
import Logo from "./../images/new/Freely-Logo.svg";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";

export default function PrivacySection() {
  return (
    <>
      <div id="privacy" className=" ">
        <div className="header relative pb-40">
          <section className=" container mx-auto  flex flex-col px-6 xl:px-20 pb-12 justify-center items-center md:pb-0 ">
            <Fade delay={400}>
              <div className="flex  py-12  justify-center  md:justify-start w-3/4  md:w-full md:pl-5 ">
                {" "}
                <Link to="/"><img src={Logo} className=" " /></Link> 
              </div>

              <div className="bg-freelyCream p-3 lg:p-20">
                <h1 className="text-2xl text-freelyBlue-dark flex mb-3">
                  Privacy Policy
                </h1>
                <hr className="w-3/4 lg:w-1/4 mb-5" />
                <p>Last modified: September 29, 2019</p>
                <p className="pb-5">
                  Talk Freely, LLC ("Freely," "we," "us" and "our") provides
                  virtual mental health care platforms intended to increase
                  access to mental health care and support and promote early
                  detection of mental health symptoms that predict the onset of
                  mental illness. Privacy is extremely important to us, so we
                  have taken several steps to protect your information and put
                  you in control.
                </p>
                <p className="pb-5">
                  This privacy policy ("Privacy Policy") describes how we
                  collect, use, disclose and store Personal Information you
                  provide to us through the website and mobile application tools
                  that provide the services described above (collectively, the
                  "Services"). Please review this Privacy Policy carefully and
                  in its entirety. If you have questions about this Privacy
                  Policy, you can contact us at the Freely Help Center.
                </p>

                <h2>This Privacy Policy describes:</h2>
                <p className="pb-5">
                  The Personal Information we collect when you interact with the
                  Services; How we use and process the Personal Information that
                  we collect; How we may share Personal Information with third
                  parties; and The security of your Personal Information.
                </p>

                <p className="pb-5">
                  The following parties will comply with this Privacy Policy:
                  All Freely employees, contractors and volunteers. All
                  affiliated entities and applications.
                </p>
                <ol className="list-decimal pl-4">
                  <li className="pb-5">
                    <p>
                      {" "}
                      USER CONSENT AND INCORPORATION PLEASE REVIEW THIS PRIVACY
                      POLICY CAREFULLY.
                    </p>
                    <p>
                      {" "}
                      When you submit your Personal Information to or through
                      the Services, you consent to the collection and processing
                      of your Personal Information as described in this Privacy
                      Policy. By using the Services, you accept the terms of
                      this Privacy Policy, our Terms of Use and consent to our
                      collection, use, disclosure and retention of your Personal
                      Information as described in this Privacy Policy. You also
                      consent to Freely sending marketing communications to the
                      email address you provide. You may opt-out (unsubscribe)
                      to these emails at any time.
                    </p>
                  </li>
                  <li className="pb-5">
                    <p> EFFECTIVE DATE AND CHANGES TO PRIVACY POLICY </p>

                    <p>
                      The Effective Date of this Privacy Policy is set forth at
                      the top of this Privacy Policy. We may change this Privacy
                      Policy from time to time in our discretion. Changes will
                      be posted to this page. We encourage you to return to this
                      page frequently so that you are aware of our current
                      privacy practices. Your continued use of the Services
                      after the Effective Date constitutes your acceptance of
                      the amended Privacy Policy. The amended Privacy Policy
                      supersedes all previous versions.
                    </p>
                  </li>
                  <li className="pb-5">
                    <p> PERSONAL INFORMATION WE COLLECT</p>

                    <p className="pb-5">
                      We require certain Personal Information to provide our
                      Services to you. We try to avoid collecting any personal
                      information that we do not require, as remaining anonymous
                      is one of our core values. "Personal Information" may
                      include contact information such as your first name, last
                      name, email address, location, phone number, certain
                      demographic information such as age, gender, race
                      ethnicity, primary language spoken, sexual orientation,
                      disabilities, and veteran status, information about your
                      health and medical history that you may input while using
                      or communicating through the Services, and information
                      about how you interact with and use your device. Freely
                      does not consider Personal Information to include
                      information that has been anonymized so that it does not
                      allow a third party to identify a specific individual.{" "}
                    </p>
                  </li>
                </ol>

                <p className="pb-5">
                  Without providing your Personal Information you may not access
                  mental health services through our Services, contact or
                  correspond with us, peers or health care providers via the
                  Services, or utilize the therapy exercises and programs
                  available on the Services.
                </p>
                <h3 className="pb-5">Account Setup</h3>
                <p className="pb-5">
                  {" "}
                  When you sign up for an account with us, we ask for and
                  collect Personal Information such as your name, a valid email
                  address, billing address, and payment information. We need
                  this information to provide you with the Services. If you
                  purchase paid services, such as therapy, other Personal
                  Information such as name, address and credit card payment
                  information will be collected to process payment.
                </p>
              </div>
            </Fade>
          </section>
        </div>
      </div>
    </>
  );
}
